// import { captureRemixErrorBoundaryError } from "@sentry/remix";
import type { LinksFunction } from "@remix-run/node";
import { isRouteErrorResponse, Link, Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useRouteError } from "@remix-run/react";

import ToasterProvider from "./components/Toaster/ToasterProvider";
import "./tailwind.css";
import { EnvKey } from "@arsenale/lib";

export const links: LinksFunction = () => [
    { rel: "preconnect", href: "https://fonts.googleapis.com" },
    {
        rel: "preconnect",
        href: "https://fonts.gstatic.com",
        crossOrigin: "anonymous",
    },
    {
        rel: "stylesheet",
        href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
    },
];

export function Layout({ children }: { children: React.ReactNode }) {
    return (
        <html lang="en">
            <head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
                <Meta />
                <Links />
            </head>
            <body>
                {children}
                <ScrollRestoration />
                <Scripts />
            </body>
        </html>
    );
}


export function loader() {
    return {
        VERSION: EnvKey.string("VERSION"),
        EMULATED: EnvKey.boolean("EMULATED"),
    }
}


export function ErrorBoundary() {
    const error = useRouteError();

    let message = "Si è verificato un errore inaspettato.";
    let status: number | undefined;

    if (isRouteErrorResponse(error)) {
        status = error.status;
        message = (error.data as string) || error.statusText;
    } else if (error instanceof Error) {
        message = error.message;
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-base-200 text-center p-4">
            <div className="max-w-lg w-full card shadow-xl bg-base-100 p-8">
                <h1 className="text-3xl font-bold text-error mb-4">Errore</h1>
                {status && <p className="text-lg font-medium mb-2">Codice: {status}</p>}
                <p className="mb-6">{message}</p>

                <div className="flex flex-col sm:flex-row justify-center gap-4">
                    <button
                        onClick={() => location.reload()}
                        className="btn btn-primary"
                    >
                        Ricarica la pagina
                    </button>
                    <Link to="/" className="btn btn-outline">
                        Torna alla Home
                    </Link>
                </div>
            </div>
        </div>
    );
}


export default function App() {
    const loader_data = useLoaderData<typeof loader>();
    return <ToasterProvider>
        <Outlet />
        <div className="fixed -z-100 inset-0 overflow-hidden opacity-45">
            <img
                src="/background.svg"
                className="object-cover w-full h-full"
            />

        </div>
        <div className="fixed bottom-0 left-0 right-0 px-4 py-1 text-sm text-center flex flex-row justify-between">
            <p className="text-nowrap">
                {loader_data.VERSION}
                {loader_data.EMULATED && <span className="text-error font-bold"> (EMULATED)</span>}
            </p>
            <p className="text-nowrap">© {new Date().getFullYear()} - SGI Servizi</p>
        </div>
    </ToasterProvider>;
}
import { IResult } from "@arsenale/lib/monad";
import { useCallback, useMemo, useRef, useState } from "react";
import ToasterContext from "./ToasterContext";


export default function ToasterProvider({ children }: { children: React.ReactNode }) {
    const ref = useRef<ReturnType<typeof setTimeout> | null>(null);
    const ref2 = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [toast, setToast] = useState<IResult<string, string> | null>(null);
    const [show, setShow] = useState(false);

    const memoSetToast = useCallback((toast: IResult<string, string>) => {
        setToast(toast);
        setShow(true);
        if (ref.current) clearTimeout(ref.current);
        ref.current = setTimeout(() => {
            setShow(false);
            if (ref2.current) clearTimeout(ref2.current);
            ref2.current = setTimeout(() => setToast(null), 300);
        }, 3000);
    }, [setToast, setShow]);

    const ctx = useMemo(() => ({
        toast,
        show,
        setToast: memoSetToast,
    }), [toast, show, memoSetToast]);

    return <ToasterContext.Provider value={ctx}>
        {children}
    </ToasterContext.Provider>;
}